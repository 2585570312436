import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, Info, InfoSmall, Title } from "@atoms/text";
import {
  CheckCircleIcon,
  TagIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
} from "@features/reception/types";
import { useReception } from "@features/reception/state/use-reception";
import { toast } from "react-hot-toast";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useState } from "react";
import { SelectedItemReception } from "@features/reception/state/store";

type AffectationModalAtomType = {
  open: boolean;
  receipt: ReceptionUnit | null;
  item: ReceptionLine | null;
  orders: RelatedOrder[];
  queingItem?: ReceptionLine | null;
  total?: number;
  automaticMode?: boolean;
  itemList?: ReceptionLine[];
};
export const AffectationCmdClientModalAtom = atom<AffectationModalAtomType>({
  key: "AffectationCmdClientModalAtom",
  default: {
    open: false,
    receipt: {} as ReceptionUnit | null,
    item: {} as ReceptionLine | null,
    orders: [] as RelatedOrder[],
    total: 0,
    automaticMode: false,
    itemList: [] as ReceptionLine[],
  },
});

export const AffectationCmdClientModal = () => {
  const [affectationCmdClientModal, setAffectationCmdClientModal] =
    useRecoilState(AffectationCmdClientModalAtom);
  const [ordersList, setOrdersList] = useState<RelatedOrder[]>([]);
  const {
    loading,
    updateLine,
    printLabels,
    changeReceiptLines,
    getLines,
    receiptLines,
    getRelatedOrders,
  } = useReception();
  const setSelectedItem = useSetRecoilState(SelectedItemReception);

  const { t } = useTranslation();

  useControlledEffect(() => {
    if (affectationCmdClientModal.orders)
      setOrdersList(
        affectationCmdClientModal.orders.filter((el) => el.numCdeCli !== "")
      );
  }, [affectationCmdClientModal.open]);

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={affectationCmdClientModal.open}
      onClose={() =>
        setAffectationCmdClientModal({
          ...affectationCmdClientModal,
          open: false,
        })
      }
    >
      <ModalContent title={"Affectation client"}>
        <div className="grow flex flex-col gap-2">
          <Frame className="flex gap-2">
            <div className="flex flex-col w-full justify-between ">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center space-x-4 ">
                  <div className="w-10 print:hidden">
                    <BookRender
                      src={
                        affectationCmdClientModal.item?.imageURL &&
                        affectationCmdClientModal.item.imageURL
                      }
                      productType={
                        (affectationCmdClientModal.receipt &&
                          affectationCmdClientModal.receipt.modeGest) ||
                        "P"
                      }
                    />
                  </div>

                  <div className="flex flex-col my-2">
                    <Base
                      className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                      data-tooltip={
                        affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.desig
                      }
                    >
                      {affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.desig}
                    </Base>
                    <Info>
                      {affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.eaN13}
                    </Info>
                  </div>
                </div>
                <div className="h-full flex items-center gap-8">
                  <Title>{`${
                    affectationCmdClientModal.orders.find(
                      (el) => el.numCdeCli === ""
                    )?.qteProposee
                  } ${t("reception.versStock")}`}</Title>
                  <div className="h-full flex flex-col gap-2 border-2 p-2 justify-center items-center">
                    <InfoSmall>Étiqueter</InfoSmall>

                    <Button
                      className="w-full"
                      size="sm"
                      theme="secondary"
                      icon={({ className }) => (
                        <TagIcon className={className} />
                      )}
                      onClick={() => {
                        if (affectationCmdClientModal.item)
                          printLabels(affectationCmdClientModal.item);
                      }}
                    />
                    <div style={{ maxWidth: 96, minWidth: 96 }}>
                      <InputCounter
                        size="sm"
                        value={
                          affectationCmdClientModal.orders.find(
                            (el) => el.numCdeCli === ""
                          )
                            ? affectationCmdClientModal.orders.find(
                                (el) => el.numCdeCli === ""
                              )!.nbrEtiq
                            : 0
                        }
                        onChange={(newValue) => {
                          const lineIndex =
                            affectationCmdClientModal.orders.findIndex(
                              (l) => l.numCdeCli === ""
                            );
                          if (lineIndex !== -1) {
                            let newOrders = [
                              ...affectationCmdClientModal.orders,
                            ];
                            newOrders[lineIndex] = {
                              ...newOrders[lineIndex],
                              nbrEtiq: newValue,
                            };

                            const stockOrder = {
                              ...affectationCmdClientModal.orders.find(
                                (el) => el.numCdeCli === ""
                              ),
                              qteProposee:
                                affectationCmdClientModal.item!.qteRecu -
                                newOrders.reduce(
                                  (sum, el) => sum + el.qteProposee,
                                  0
                                ),
                            };
                            if (stockOrder)
                              setAffectationCmdClientModal({
                                ...affectationCmdClientModal,
                                orders: [...newOrders],
                              });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Frame>
          <Table
            className="mt-2"
            emptyTabText="Aucune commande client associée à cet article"
            columns={[
              {
                title: t("reception.quantityToAffect"),
                render: (order) => (
                  <div style={{ maxWidth: 120, minWidth: 120 }}>
                    <InputCounter
                      deleteIconOnZero={true}
                      size="md"
                      max={affectationCmdClientModal.item?.qteRecu}
                      min={0}
                      value={order.qteProposee}
                      onChange={(newValue) => {
                        const lineIndex = ordersList.findIndex(
                          (l) => l.numCdeCli === order.numCdeCli
                        );
                        if (lineIndex !== -1) {
                          let newOrders = [...ordersList];
                          const tempOrder = { ...newOrders[lineIndex] };
                          tempOrder.qteProposee = newValue;
                          newOrders[lineIndex] = tempOrder;

                          const stockOrder = {
                            ...affectationCmdClientModal.orders.find(
                              (el) => el.numCdeCli === ""
                            ),
                          } as RelatedOrder;
                          stockOrder.qteProposee =
                            affectationCmdClientModal.item!.qteRecu -
                            newOrders.reduce(
                              (sum, el) => sum + el.qteProposee,
                              0
                            );

                          setAffectationCmdClientModal({
                            ...affectationCmdClientModal,
                            orders: [stockOrder, ...newOrders],
                          });
                          setOrdersList(newOrders);
                        }
                      }}
                    />
                  </div>
                ),
              },
              {
                title: t("reception.infosCommandes"),
                render: (order) => (
                  <Base>
                    {order.qteCde} cdé cli {order.codeCanalVente}
                  </Base>
                ),
              },
              {
                title: t("reception.Client"),
                orderable: true,
                render: (order) => (
                  <div className="flex flex-col gap-2">
                    <Base>{order.nomClient}</Base>
                    <InfoSmall>
                      {order.mailCli} {order.telCli}
                    </InfoSmall>
                  </div>
                ),
              },
              {
                title: "Étiqueter",
                render: (order, i) => (
                  <div className="flex gap-2">
                    <Button
                      className="shrink-0"
                      size="sm"
                      theme="secondary"
                      icon={({ className }) => (
                        <TagIcon className={className} />
                      )}
                    />
                    <div style={{ maxWidth: 96, minWidth: 96 }}>
                      <InputCounter
                        size="sm"
                        value={order.nbrEtiq}
                        min={0}
                        onChange={(newValue) => {
                          const lineIndex = ordersList.findIndex(
                            (l) => l.numCdeCli === order.numCdeCli
                          );
                          if (lineIndex !== -1) {
                            let newOrders = [...ordersList];
                            const tempOrder = { ...newOrders[lineIndex] };
                            tempOrder.nbrEtiq = newValue;
                            newOrders[lineIndex] = tempOrder;
                            const stockOrder = {
                              ...affectationCmdClientModal.orders.find(
                                (el) => el.numCdeCli === ""
                              ),
                            } as RelatedOrder;

                            setAffectationCmdClientModal({
                              ...affectationCmdClientModal,
                              orders: [...newOrders, stockOrder],
                            });
                            setOrdersList(newOrders);
                          }
                        }}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
            //data={[...ordersList, ...relatedOrders]}
            data={ordersList}
          />
          <div className="w-full flex justify-center gap-8 my-4">
            <Button
              disabled={loading}
              theme="danger"
              icon={(p) => <XCircleIcon {...p} />}
              onClick={() => {
                setAffectationCmdClientModal({
                  ...affectationCmdClientModal,
                  open: false,
                });
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              disabled={loading}
              theme="primary"
              icon={(p) => <CheckCircleIcon {...p} />}
              onClick={async () => {
                console.log(affectationCmdClientModal.automaticMode);
                console.log(affectationCmdClientModal.itemList);
                if (affectationCmdClientModal.item) {
                  const res = await updateLine(
                    affectationCmdClientModal.item,
                    affectationCmdClientModal.orders
                  );
                  if (!res) {
                    toast.error("Impossible d'affecter les lignes !");
                  } else {
                    toast.success("Lignes affectée avec succès !");
                    if (affectationCmdClientModal.queingItem) {
                      const index = receiptLines.items.findIndex(
                        (el) =>
                          el.linePk ===
                          affectationCmdClientModal.queingItem?.linePk
                      );
                      setSelectedItem({
                        ...affectationCmdClientModal.queingItem,
                        qteRecu:
                          index !== -1 ? receiptLines.items[index].qteRecu : 1,
                      });
                    } else {
                      setSelectedItem(null);
                    }
                    if (
                      affectationCmdClientModal.automaticMode &&
                      affectationCmdClientModal.itemList &&
                      affectationCmdClientModal.itemList.length > 1
                    ) {
                      console.log("HERE WE ARE");
                      const tempTab = [
                        ...affectationCmdClientModal.itemList,
                      ].slice(1);
                      if (tempTab[0] && affectationCmdClientModal.receipt) {
                        const nextItemOrders = await getRelatedOrders(
                          affectationCmdClientModal.receipt,
                          tempTab[0].eaN13,
                          tempTab[0].linePk,
                          tempTab[0].qteRecu,
                          true
                        );
                        setAffectationCmdClientModal({
                          ...affectationCmdClientModal,
                          item: tempTab[0],
                          orders: nextItemOrders,
                          itemList: tempTab,
                        });
                      }
                    } else {
                      console.log("GONNA CLOSE");
                      const updatedLines = await getLines(
                        affectationCmdClientModal.receipt!,
                        {
                          orderBy: "eaN13",
                          orderDir: "DESC",
                          pageNumber: 0,
                          pageSize: 15,
                        }
                      );
                      changeReceiptLines({
                        pagination: {
                          pageNumber: updatedLines.pageNumber,
                          pageSize: updatedLines.pageSize,
                          pageCount: updatedLines.pageCount,
                          totalRows: updatedLines.totalRows,
                          rowsReturned: updatedLines.rowsReturned,
                        },
                        items: updatedLines.items,
                      });

                      setAffectationCmdClientModal({
                        receipt: null,
                        item: null,
                        orders: [],
                        open: false,
                        queingItem: null,
                      });
                    }
                  }
                }
              }}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};

//Fake data for display debug
// let relatedOrders: RelatedOrder[] = [
//   {
//     codeCanalVente: "exempleCode1",
//     numCdeCli: "Cde12345",
//     dateCde: "2024-03-29",
//     nomClient: "NomClient1",
//     telCli: "0102030405",
//     mailCli: "client1@example.com",
//     qteCde: 10,
//     qteRest: 5,
//     qteProposee: 3,
//     nbrEtiq: 2,
//   },
//   {
//     codeCanalVente: "exempleCode2",
//     numCdeCli: "Cde67890",
//     dateCde: "2024-03-30",
//     nomClient: "NomClient2",
//     telCli: "0607080910",
//     mailCli: "client2@example.com",
//     qteCde: 20,
//     qteRest: 10,
//     qteProposee: 6,
//     nbrEtiq: 4,
//   },
// ];
