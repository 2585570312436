import { fetchServer } from "@features/utils/fetch-server";
import {
  MissingArticle,
  ReceptionLine,
  ReceptionSearchResult,
  ReceptionUnit,
  RelatedOrder,
  SearchReceptionRequestType,
  Taux,
} from "../types";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import { AuthJWT } from "@features/general/auth/jwt";
import { toDateISO } from "@features/utils/format/dates";
export class ReceptionApiClient {
  static mainSearch = async (search: SearchReceptionRequestType) => {
    const data = await fetchServer("/receipt/search", {
      method: "POST",
      body: JSON.stringify(search),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result.items as ReceptionSearchResult[];
  };

  static allReceipt = async (
    pagination?: CommonPaginationRequest,
    filters?: { utilCreat?: string; codeFourn?: string; trait?: boolean }
  ) => {
    const data = await fetchServer("/receipt/all", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "dateCreat",
        orderDir: pagination?.orderDir || "DESC",
        // addtionalSorts: [
        //   { orderBy: "codeFourn", orderDir: "ASC" },
        //   {
        //     orderBy: "dateCreat",
        //     orderDir: "DESC",
        //   },
        // ],
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 0,
        filters: filters,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      pagination: {
        pageNumber: result.pageNumber,
        pageSize: result.pageSize,
        pageCount: result.pageCount,
        totalRows: result.totalRows,
        rowsReturned: result.rowsReturned,
      } as CommonPagination,
      items: result.items as ReceptionUnit[],
    };
  };

  static getReceipt = async (receipt: ReceptionUnit) => {
    const data = await fetchServer("/receipt", {
      method: "POST",
      body: JSON.stringify({
        codeFourn: receipt.codeFourn,
        numBL: receipt.numBL,
        seq: receipt.seq,
        codeLieu: receipt.codeLieu,
        dateRecep: receipt.dateRecep,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ReceptionUnit;
  };

  static deleteReceipt = async (receipt: ReceptionUnit) => {
    const data = await fetchServer("/receipt/delete", {
      method: "delete",
      body: JSON.stringify({
        codeFourn: receipt.codeFourn,
        numBL: receipt.numBL,
        seq: receipt.seq,
        codeLieu: receipt.codeLieu,
        dateRecep: receipt.dateRecep,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static printLabels = async (
    recept: ReceptionUnit,
    receptLine?: ReceptionLine,
    nbrEtiq?: number
  ) => {
    const data = await fetchServer("/receipt/label", {
      method: "POST",
      body: JSON.stringify({
        receipt: {
          codeFourn: recept.codeFourn,
          numBL: recept.numBL,
          seq: recept.seq,
          codeLieu: recept.codeLieu,
          dateRecep: toDateISO(recept.dateRecep),
        },
        codeCanalVente: recept.codeCanalVente,
        nbrEtiq: nbrEtiq || 1,
        linePk: (receptLine && receptLine.linePk) || null,
      }),
    });
    if (data.status !== 204) {
      const error = await data.json();
      throw new Error(error.title);
    }
    return true;
  };

  static getReceiptSugg = async (term: string) => {
    const data = await fetchServer(
      `/receipt/suggestrecep?term=${term}&codeLieu=${AuthJWT.codeLieu}`
    );
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ReceptionUnit;
  };

  static getTaux = async (codeTypeProd?: string) => {
    const fetchUrl = codeTypeProd
      ? `/ratetype?codeTypeProd=${codeTypeProd}`
      : `/ratetype`;
    const data = await fetchServer(fetchUrl);
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as Taux[];
  };

  static getLines = async (
    receipt: ReceptionUnit,
    pagination?: CommonPaginationRequest
  ) => {
    const data = await fetchServer("/receipt/lines", {
      method: "POST",
      body: JSON.stringify({
        orderBy: pagination?.orderBy || "ean13",
        orderDir: pagination?.orderDir || "DESC",
        pageNumber: pagination?.pageNumber || 1,
        pageSize: pagination?.pageSize || 15,
        receipt: {
          codeFourn: receipt.codeFourn,
          numBL: receipt.numBL,
          seq: receipt.seq,
          codeLieu: receipt.codeLieu,
          dateRecep: toDateISO(receipt.dateRecep),
        },
        eaN13: "",
        numColis: receipt.numColis ? +receipt.numColis : -1,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result;
  };

  static getHeaderReceipt = async (infos: {
    codeLieu: string;
    orig: "AE" | "COLIS" | "CDE" | "HCDE";
    codeFourn: string;
    numBL: string;
    numColis: string;
    numCde: string;
  }) => {
    const data = await fetchServer("/receipt/header", {
      method: "POST",
      body: JSON.stringify({
        codeLieu: infos.codeLieu,
        orig: infos.orig,
        codeFourn: infos.codeFourn,
        numBL: infos.numBL,
        numColis: parseInt(infos.numColis) || undefined,
        numCde: infos.numCde,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as ReceptionUnit;
  };

  static getScannedProduct = async (infos: {
    receipt: {
      codeFourn: string;
      numBL: string;
      seq: number;
      codeLieu: string;
      dateRecep: string;
    };
    productId: string;
  }) => {
    const data = await fetchServer("/receipt/product", {
      method: "POST",
      body: JSON.stringify({
        receipt: {
          codeFourn: infos.receipt.codeFourn,
          numBL: infos.receipt.numBL,
          seq: infos.receipt.seq,
          codeLieu: infos.receipt.codeLieu,
          dateRecep: infos.receipt.dateRecep,
        },
        productId: infos.productId,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return {
      ...result,
      rayonObj: { label: result.libRayon, value: result.codeRayon },
      fabObj: { label: result.editFab, value: result.codeEditFab },
    } as ReceptionLine;
  };

  static getRelatedOrders = async (
    receipt: ReceptionUnit,
    productId: string,
    linePk: string,
    qteRecu: number
  ) => {
    const data = await fetchServer("/receipt/product/orders", {
      method: "POST",
      body: JSON.stringify({
        receipt: {
          codeFourn: receipt.codeFourn,
          numBL: receipt.numBL,
          seq: receipt.seq,
          codeLieu: receipt.codeLieu,
          dateRecep: toDateISO(receipt.dateRecep),
        },
        productId: productId,
        linePk: linePk,
        qteRecu: qteRecu,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as RelatedOrder[];
  };

  static createHeader = async (recept: ReceptionUnit) => {
    const data = await fetchServer("/receipt/header", {
      method: "PUT",
      body: JSON.stringify({
        ...recept,
        listReceiptCdeFourn:
          recept.listReceiptCdeFourn?.length === 1 &&
          recept.listReceiptCdeFourn.find((el) => el.numCde === null)
            ? []
            : recept.listReceiptCdeFourn,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      //toast.error(result.detail);
      throw new Error(result.title);
    }
    return true;
  };

  static updateLine = async (
    recept: ReceptionUnit,
    product: ReceptionLine,
    orders?: RelatedOrder[]
  ) => {
    const data = await fetchServer("/receipt/line", {
      method: "PATCH",
      body: JSON.stringify({
        receipt: {
          codeFourn: product.codeFourn,
          numBL: recept.numBL,
          seq: recept.seq,
          codeLieu: recept.codeLieu,
          dateRecep: recept.dateRecep,
        },
        eaN13: product.eaN13,
        prixBase: product.prixBase,
        tauxNet: `${product.tauxNet}`,
        prixNetHt: product.prixNetHt,
        codePrix: product.codePrix,
        codeEditFab: product.codeEditFab,
        editFab: product.editFab,
        codeRayon: product.codeRayon,
        libRayon: product.libRayon,
        orders: orders,
        linePk: product.linePk,
        nbrEtiq: product.nbrEtiq,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static rappatrierLines = async (recept: ReceptionUnit) => {
    const data = await fetchServer("/receipt/lines/repatriation", {
      method: "PUT",
      body: JSON.stringify({
        receipt: {
          codeFourn: recept.codeFourn,
          numBL: recept.numBL.toString(),
          seq: recept.seq,
          codeLieu: recept.codeLieu,
          dateRecep: toDateISO(recept.dateRecep),
        },
        orig: recept.orig,
        numColis: recept.numColis,
        numCde: recept.numCde,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      //toast.error(result.detail);
      throw new Error(result.title);
    }
    return result as { ean13: string; designation: string }[];
  };

  static closeReceipt = async (receipt: ReceptionUnit) => {
    const data = await fetchServer("/receipt/close", {
      method: "POST",
      body: JSON.stringify({
        numRecep: receipt.numRecep,
        numColis: receipt.numColis ? receipt.numColis : null,
        bord: receipt.bord,
        codeTypeAchat: receipt.codeTypeAchat,
        fraisPortHt: +receipt.fraisPortHt,
        fraisPortTtc: +receipt.fraisPortTtc,
        codeTaxe: receipt.codeTaxe,
        codeTypeTaux: receipt.codeTypeTaux,
        indicFactFourn: receipt.indicFactFourn,
      }),
    });

    if (data.status !== 204) {
      throw new Error("ERROR");
    }
    return true;
  };

  static updateFdp = async (receipt: ReceptionUnit) => {
    const data = await fetchServer("/receipt/fraisDePort", {
      method: "PATCH",
      body: JSON.stringify({
        numRecep: receipt.numRecep,
        fraisPortHt: +receipt.fraisPortHt,
        codeTaxe: receipt.codeTaxe,
        fraisPortTtc: +receipt.fraisPortTtc,
      }),
    });

    if (data.status !== 204) {
      throw new Error("ERROR");
    }
    return true;
  };

  static getReceiptDocument = async (
    receipt: ReceptionUnit,
    documentType: "ecart" | "recep"
  ) => {
    const data = await fetchServer("/receipt/document", {
      method: "POST",
      body: JSON.stringify({
        receipt: {
          codeFourn: receipt.codeFourn,
          numBL: receipt.numBL,
          seq: receipt.seq,
          codeLieu: receipt.codeLieu,
          dateRecep: receipt.dateRecep,
        },
        modeGest: receipt.modeGest,
        codeTypeProd: receipt.codeTypeProd,
        type: documentType,
      }),
    });
    if (data.status !== 200 && data.status !== 204) {
      throw new Error("ERROR GENERATING PDF");
    }
    return await data.blob();
  };

  static processing = async (
    recept: ReceptionUnit,
    activationStatus: boolean = true
  ) => {
    const data = await fetchServer(
      `/receipt/processing?status=${activationStatus}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          codeFourn: recept.codeFourn,
          numBL: recept.numBL,
          seq: recept.seq,
          codeLieu: recept.codeLieu,
          dateRecep: recept.dateRecep,
        }),
      }
    );
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static valoLine = async (
    reception: ReceptionUnit,
    line: ReceptionLine,
    orig: string
  ) => {
    // console.log(line);
    //console.log(line.tauxNet);
    // console.log(
    //   JSON.stringify({
    //     codeLieu: reception.codeLieu,
    //     codeFourn: line.codeFourn,
    //     ean13: line.eaN13,
    //     prixBase: line.prixBase,
    //     tauxNet: `${line.tauxNet}`,
    //     netHT: line.prixNetHt,
    //     orig:
    //       orig === "prixBase"
    //         ? "prix_base"
    //         : orig === "tauxNet"
    //         ? "taux_net"
    //         : orig === "prixNetHt"
    //         ? "net_ht"
    //         : "",
    //   })
    // );
    const data = await fetchServer("/receipt/line/valo", {
      method: "POST",
      body: JSON.stringify({
        codeLieu: reception.codeLieu,
        codeFourn: line.codeFourn,
        ean13: line.eaN13,
        prixBase: line.prixBase,
        tauxNet: `${line.tauxNet}`,
        netHT: line.prixNetHt,
        orig:
          orig === "prixBase"
            ? "prix_base"
            : orig === "tauxNet"
            ? "taux_net"
            : orig === "prixNetHt"
            ? "net_ht"
            : "",
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as { tauxNet: number; netHT: number };
  };

  static getMissingArticles = async (reception: ReceptionUnit) => {
    const data = await fetchServer("/receipt/missing", {
      method: "POST",
      body: JSON.stringify({
        codeFourn: reception.codeFourn,
        numBL: reception.numBL,
        codeLieu: reception.codeLieu,
        dateRecep: reception.dateRecep,
        seq: reception.seq,
      }),
    });
    const result = await data.json();
    if (data.status !== 200) {
      throw new Error(result.title);
    }
    return result as MissingArticle[];
  };

  static updateMissingArticles = async (
    recept: ReceptionUnit,
    missingArticles: MissingArticle[]
  ) => {
    const data = await fetchServer("/receipt/missing", {
      method: "PATCH",
      body: JSON.stringify({
        receipt: {
          codeFourn: recept.codeFourn,
          numBL: recept.numBL,
          seq: recept.seq,
          codeLieu: recept.codeLieu,
          dateRecep: recept.dateRecep,
        },
        receiptMissingRequests: missingArticles,
      }),
    });

    const result = await data.json();
    if (data.status !== 200) {
      const result = await data.json();
      throw new Error(result.title);
    }

    return result as {
      success: boolean;
      errorMsg: string;
      numCde: string;
      numLigne: number;
      ean13: string;
      act: string;
    }[];
  };

  static updateAllPrices = async (recept: ReceptionUnit) => {
    const data = await fetchServer("/receipt/productprice", {
      method: "PATCH",
      body: JSON.stringify({
        codeFourn: recept.codeFourn,
        numBL: recept.numBL,
        seq: recept.seq,
        codeLieu: recept.codeLieu,
        dateRecep: recept.dateRecep,
      }),
    });
    if (data.status !== 204) {
      const result = await data.json();
      throw new Error(result.title);
    }
    return true;
  };

  static updatePrices = async () => {
    // const data = await fetchServer("/receipt", {
    //   method: "POST",
    //   body: JSON.stringify({}),
    // });
    // const result = await data.json();
    // if (data.status !== 200) {
    //   throw new Error(result.title);
    // }
    // return result;
    return true;
  };
}
