import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info } from "@atoms/text";
import { useReception } from "@features/reception/state/use-reception";
import { ReceptionLine } from "@features/reception/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

export const PrintLabelsModal = atom({
  key: "PrintLabelsModalAtom",
  default: { open: false, item: {} as ReceptionLine | null },
});

export const PrintLabelsReceptionModal = () => {
  const [printLabelsModals, setPrintLabelsModal] =
    useRecoilState(PrintLabelsModal);
  const [nbrEtiq, setNbrEtiq] = useState(1);
  const { printLabels, loading } = useReception();

  const handleClose = () => setPrintLabelsModal({ open: false, item: null });

  useControlledEffect(() => {
    if (printLabelsModals.open)
      setNbrEtiq(printLabelsModals.item?.qteRecu || 1);
  }, [printLabelsModals.open]);
  return (
    <Modal
      className="sm:max-w-[450px]"
      open={printLabelsModals.open}
      onClose={() => handleClose()}
    >
      <ModalContent title={"Impression d'étiquettes"}>
        <div className="flex flex-col gap-2">
          <Info>{`${printLabelsModals.item?.desig}`} </Info>
          <Info>{`${printLabelsModals.item?.eaN13}`} </Info>
          <div className="flex items-center gap-2 my-2">
            <InputCounter
              size="md"
              min={1}
              value={nbrEtiq}
              onChange={(value) => {
                if (value < 1) {
                  setNbrEtiq(1);
                  return;
                }
                setNbrEtiq(value);
              }}
            />
            <Button
              disabled={loading}
              onClick={async () => {
                await printLabels(
                  printLabelsModals.item as ReceptionLine,
                  nbrEtiq
                );
                handleClose();
              }}
            >
              Imprimer
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
